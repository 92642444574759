<template>
  <!-- Tile -->
  <UserDetails :userDetails="$props">
    <template slot="button">
      <div v-if="display === displayListTypes.CARDS" class="tile-wrapper">
        <v-card
          class="tile"
          :flat="!isSelected"
          :height="isMobile ? 'auto' : 180"
          :class="{ errorBorder: error }"
          :ripple="false"
        >
          <v-layout justify-space-between column overflow-hidden>
            <v-card-text>
              <div class="tile-content">
                <div class="tile-content-details">
                  <v-layout justify-space-between fill-height wrap>
                    <v-layout>
                      <v-flex xs6>
                        <TooltipTruncate :attrs="'subheading bold'">
                          {{ name }}
                        </TooltipTruncate>
                      </v-flex>
                      <v-flex xs6>
                        <TooltipTruncate :title="email">
                          <span class="label grey--text text-lowercase">
                            {{ `${$t("user.email")}: ` }}
                          </span>
                          {{ email }}
                        </TooltipTruncate>

                        <TooltipTruncate :title="login">
                          <span class="label grey--text text-lowercase">
                            {{ `${$t("user.login")}: ` }}
                          </span>
                          {{ login }}
                        </TooltipTruncate>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap align-top justify-start>
                      <v-flex xs1>
                        <IconBox
                          class="mr-2"
                          color="iconButton"
                          :tooltip="$t('user.roles')"
                        >
                          stem-role
                        </IconBox>
                      </v-flex>
                      <v-flex xs11 mt-1>
                        <v-layout wrap align-start justify-start fill-height>
                          <v-flex xs4 v-for="item in roles" :key="item.id">
                            <TooltipTruncate>{{ item.text }}</TooltipTruncate>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex my-1 xs12>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-flex xs6>
                        <span class="grey--text">
                          {{ $t("user.insertDate") }}:
                        </span>
                        {{ insertDate | moment("displayDate") }}
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </div>

                <div class="tile-content-buttons">
                  <div class="select-field">
                    <v-checkbox
                      v-if="!isDefaultAdmin"
                      color="fields"
                      :input-value="isSelected"
                      @click.stop.prevent="$emit('select')"
                    />
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-layout>
        </v-card>
        <span class="error--text error-message" v-if="error">{{ error }}</span>
      </div>

      <!-- Inline -->
      <div v-else class="inline-wrapper">
        <v-card
          class="inline"
          :flat="!isSelected"
          :class="{ errorBorder: error }"
        >
          <v-card-text>
            <div class="inline-content">
              <v-layout
                overflow-hidden
                justify-space-between
                align-center
                class="inline-content-details textColor--text"
              >
                <v-flex xs3>
                  <TooltipTruncate :attrs="'subheading bold'">
                    {{ name }}
                  </TooltipTruncate>
                </v-flex>
                <v-flex xs2>
                  <span class="label">{{ $t("user.login") }}</span>
                  <TooltipTruncate>{{ login }}</TooltipTruncate>
                </v-flex>
                <v-flex xs2>
                  <span class="label">{{ $t("user.email") }}</span>
                  <TooltipTruncate>{{ email }}</TooltipTruncate>
                </v-flex>
                <v-flex xs2>
                  <span class="label">{{ $t("user.insertDate") }}</span>
                  <div>{{ insertDate | moment("displayDate") }}</div>
                </v-flex>
                <v-flex xs3>
                  <v-layout overflow-hidden justify-start align-center>
                    <IconBox
                      :styles="{ 'margin-right': '5px' }"
                      color="iconButton"
                      :tooltip="$t('user.roles')"
                    >
                      stem-role
                    </IconBox>
                    <TooltipTruncate>
                      {{ roles.map((x) => x.text) | join }}
                    </TooltipTruncate>
                  </v-layout>
                </v-flex>
              </v-layout>

              <div class="inline-content-buttons">
                <div class="select-field">
                  <v-checkbox
                    v-if="!isDefaultAdmin"
                    color="fields"
                    :input-value="isSelected"
                    @click.stop.prevent="$emit('select')"
                  />
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <span class="error--text error-message" v-if="error">{{ error }}</span>
      </div>
    </template>
  </UserDetails>
</template>

<script>
import UserDetails from "./UserDetails";
import DisplayListTypes from "@/models/displayListTypes.js";
import { mapGetters } from "vuex";

export default {
  components: {
    UserDetails,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    customerId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    firstname: {
      type: String,
      required: true,
    },
    surname: {
      type: String,
      required: true,
    },
    login: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
    insertDate: {
      type: String,
    },
    roles: {
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isLocalUser: {
      type: Boolean,
      default: true,
    },
    display: {
      type: String,
      default: DisplayListTypes.CARDS,
    },
    color: {
      type: String,
      default: "grey",
    },
    department: {
      type: Object,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      displayListTypes: DisplayListTypes,
    };
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    isDefaultAdmin() {
      return (
        this.id === "82c92375-7206-4181-96c8-feaf994f897e" &&
        this.login === "admin"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.tile {
  .tile-content {
    .label {
      font-size: 13px;
    }

    .tile-content-buttons {
      display: flex;
      margin-top: 10px;
      height: 30px;
      .select-field {
        margin-left: auto;

        .v-input--selection-controls.v-input {
          margin: 0;
        }
      }
    }
  }
}

.inline {
  .inline-content {
    display: flex;
    flex-direction: row;

    .inline-content-details {
      .label {
        color: $label;
      }
    }
  }
}

.tile:hover,
.inline:hover {
  background: $card-hover;
}

.tile-wrapper,
.inline-wrapper {
  box-shadow: $card-shadow;
}

.error-message {
  font-size: 13px;
}

.errorBorder {
  box-shadow: inset 0 0 0 2px $error;
}
</style>

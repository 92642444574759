import {
  required,
  requiredIf,
  maxLength,
  minLength,
  email,
  numeric,
} from "vuelidate/lib/validators";

import validationThings from "../../mixins/validation-things";

export default {
  mixins: [validationThings],
  data() {
    return {
      validatedProperty: "user",
      customValidationDefinition: [
        {
          name: "rolesLCategories",
          definition: _arrayCustomValidationDefinition,
        },
      ],
    };
  },
  validations: {
    user: {
      firstname: {
        required,
        maxLength: maxLength(50),
      },
      surname: {
        required,
        maxLength: maxLength(50),
      },
      login: {
        required,
        maxLength: maxLength(50),
        login,
      },
      email: {
        required: requiredIf((model) => {
          return model.isLocalUser;
        }),
        email,
      },
      phone: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(13),
        numericOrPlus,
      },
      rolesLCategories: {
        $each: {
          roleId: {
            required,
          },
        },
      },
      department: {
        maxLength: maxLength(265),
      },
    },
  },
};

function numericOrPlus(value) {
  if (value && numeric(value.substring(1))) {
    if (value[0] == "+" || !isNaN(value[0])) return true;
  }
  return false;
}

function _arrayCustomValidationDefinition(param, rulesText) {
  let resultArray = [];

  Object.keys(param.$each.$iter).forEach((el) => {
    let roleParams = param.$each[el].roleId.$params;
    let elResultArray = {
      roles: [],
    };

    Object.keys(roleParams).forEach((key) => {
      if (!param.$each[el].roleId[key]) {
        elResultArray.roles.push(rulesText[key]);
      }
    });

    resultArray.push(elResultArray);
  });

  return resultArray;
}

function login(value) {
  const regex = /^[a-zA-Z0-9@.\-_]+$/g;

  return regex.test(value);
}

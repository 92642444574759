import axios from "../plugins/http";
import WithUser from "../utils/WithUser";
const CancelToken = axios.CancelToken;
let cancel;
let apiUrl = "api/newUser";

export default {
  GetRoles() {
    return axios.get(`${apiUrl}/roles`);
  },
  GetCategories() {
    return axios.get(`${apiUrl}/categories`);
  },
  GetIdentityUsers(search) {
    let params = { search };
    if (cancel !== undefined) {
      cancel();
    }

    return axios.get(`${apiUrl}/search`, {
      params,
      cancelToken: new CancelToken(function executor(x) {
        cancel = x;
      }),
    });
  },
  Create(model) {
    return axios.post(apiUrl, WithUser(model));
  },
  isUserLoginExists(params) {
    return axios.get(`${apiUrl}/isUserLoginExists`, {
      params,
    });
  },
};

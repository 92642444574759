<template>
  <DataGrid
    :card="card"
    v-model="selectedUsers"
    :display.sync="display"
    :search="searchText"
    @update:search="searchHandler"
    :items="users"
    :pagination="pagination"
    @update:pagination="getUsers"
    :isLoading="isLoading"
    :minimumCardWidth="425"
  >
    <template #header-buttons>
      <div class="header-buttons">
        <v-btn
          rounded
          color="navigationPageButtons"
          class="header-navigation-button white--text"
          v-can:CFG_WRITE
        >
          {{ $t("objects.users") }}
        </v-btn>
        <v-btn
          rounded
          text
          v-can:CFG_WRITE
          class="header-navigation-button"
          :to="{ name: 'roles' }"
        >
          {{ $t("objects.roles") }}
        </v-btn>
      </div>
    </template>
    <template #header>
      <div class="header" v-bind:class="isMobile && 'mobile'">
        <OrderPicker :options="orderByOptions" v-model="orderBy" />
        <FiltersPicker :categories="filterOptions" v-model="filters" />
      </div>
    </template>

    <template #toolbar>
      <v-spacer></v-spacer>
      <NewUser class="toolbar-buttons" @user-created="getUsers" />
      <ConfirmModal
        class="toolbar-buttons"
        v-model="isConfirmDeactivateModalShown"
        @confirm="deactivateUsers"
        @cancel="isConfirmDeactivateModalShown = false"
        :confirmText="
          $t('global.confirmTextCount', {
            count: selectedUsers ? selectedUsers.length : 0,
          })
        "
      ></ConfirmModal>
      <v-btn
        :disabled="!selectedUsers || selectedUsers.length === 0"
        large
        icon
        text
        class="toolbar-buttons"
        @click="tryDelete"
      >
        <IconBox :tooltip="$t('user.deleteUser')" color="iconButton">
          stem-delete
        </IconBox>
      </v-btn>
      <SearchDeletedUsers class="toolbar-buttons" @onSelect="onRestoreUser" />
    </template>
  </DataGrid>
</template>

<script>
import { mapProps } from "@/store";
import { mapGetters } from "vuex";

import UsersService from "@/services/UsersService.js";
import NewUserService from "@/services/NewUserService";

import EventBus, { EmitSuccess, EmitError } from "@/eventBus.js";
import { GetErrorData, GetErrorMsg } from "@/utils/ServerErrorHandler.js";

import DataGrid from "@/components/Shared/DataGrid";
import UserCard from "@/components/Users/UserCard";
import NewUser from "@/components/Users/NewUser";
import FiltersPicker from "../components/Shared/FiltersPicker";
import OrderPicker from "../components/Shared/OrderPicker";
import ConfirmModal from "../components/Shared/ConfirmModal";
import SearchDeletedUsers from "@/components/Users/SearchDeletedUsers";

export default {
  components: {
    DataGrid,
    NewUser,
    FiltersPicker,
    OrderPicker,
    ConfirmModal,
    SearchDeletedUsers,
  },
  data() {
    return {
      isConfirmDeactivateModalShown: false,
      isLoading: false,
      card: UserCard,
      users: [],
      orderByOptions: ["SURNAME", "INSERTDATE"],
      filterOptions: [],
      roles: [],
      searchText: "",
    };
  },
  created() {
    this.isLoading = true;
    Promise.all([this.getRoles()])
      .then(() => {
        this._setFilters();
        this.getUsers();
      })
      .catch(() => (this.isLoading = false));
  },
  mounted() {
    EventBus.$on("eb-update-user-list", this.updateUserListener);
  },
  destroyed() {
    EventBus.$off("eb-update-user-list", this.updateUserListener);
  },
  methods: {
    updateUserListener() {
      this.getUsers();
    },
    getRoles() {
      return NewUserService.GetRoles().then((response) => {
        this.roles = response;
      });
    },
    getUsers(pagination) {
      return UsersService.GetUsers({
        ...this.filtersDto,
        ...this._getPagination(pagination),
      })
        .then((res) => {
          res.data.forEach((d) => (d.error = ""));
          this.users = res.data;
          this.pagination = {
            ...this.pagination,
            page: res.pagination.currentPage,
            itemsPerPage: res.pagination.itemsPerPage,
            itemsLength: res.pagination.totalItems,
            pageStart:
              (res.pagination.currentPage - 1) * res.pagination.itemsPerPage,
            pageStop: res.pagination.currentPage * res.pagination.itemsPerPage,
            pageCount: Math.ceil(
              res.pagination.totalItems / res.pagination.itemsPerPage
            ),
          };
        })
        .finally(() => (this.isLoading = false));
    },
    onSave(item) {
      this.$refs["userGrid"].items.push(item.res);
    },
    _getPagination(pagination = this.pagination) {
      return {
        pageNumber: pagination.page,
        pageSize: pagination.itemsPerPage,
      };
    },
    searchHandler(search) {
      this.pagination.page = 1;
      this.searchText = search;
    },
    formatFilters() {
      if (Object.entries(this.filters).length > 0) {
        return {
          roles: [...this.filters.roles],
        };
      }
      return null;
    },
    _setFilters() {
      const options = [
        {
          type: "multiSelect",
          name: this.$t("orderBy.ROLE"),
          value: "roles",
          options: this.roles.map((g) => {
            return { name: g.name, value: g.id };
          }),
        },
      ];
      this.filterOptions.push(...options);
    },
    tryDelete() {
      if (this.selectedUsers.length > 0) {
        this.isConfirmDeactivateModalShown = true;
      }
    },
    deactivateUsers() {
      this.isConfirmDeactivateModalShown = false;

      this.selectedUsers.forEach((user) => {
        if (user.id === this.oidcUser.userId) {
          user.error = this.$t("user.selfDeleteError");
        }
      });

      if (this.selectedUsers.every((u) => u.id !== this.oidcUser.userId)) {
        UsersService.Deactivate(this.selectedUsers.map((x) => x.id))
          .then(() => {
            if (this.selectedUsers.length > 1) {
              EmitSuccess(this.$t("user.deleteSuccess"));
            } else {
              EmitSuccess(this.$t("user.deleteSingleSuccess"));
            }
            this.selectedUsers = [];
            this.getUsers();
          })
          .catch((err) => {
            const errorData = GetErrorData(err);
            if (errorData && errorData.errors) {
              Object.entries(errorData.errors).forEach(([id, value]) => {
                const user = this.selectedRoles.find((r) => r.id === +id);
                if (user) user.error = GetErrorMsg(value.errorCode);
              });
            }
          });
      }
    },
    onRestoreUser(user) {
      UsersService.RestoreDeletedUser(user)
        .then(() => {
          EmitSuccess(this.$t("user.restoreDeletedUserSuccess"));
          this.selectedUsers = [];
          this.getUsers();
        })
        .catch(() => {
          EmitError(this.$t("user.restoreDeletedUserError"));
        });
    },
  },
  computed: {
    ...mapProps(
      ["orderBy", "filters", "display", "pagination", "selectedUsers"],
      {
        options: "userOptions",
        action: "saveUserOption",
      }
    ),
    ...mapGetters("settings", ["isMobile"]),
    ...mapGetters("oidcStore", ["oidcUser"]),
    filtersDto() {
      return {
        orderBy: this.orderBy.name,
        orderDesc: this.orderBy.desc,
        search: this.searchText,
        ...this.formatFilters(),
      };
    },
  },
  watch: {
    filtersDto: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        this.pagination.page = 1;
        this.getUsers();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.header-buttons {
  .header-navigation-button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}

.toolbar-buttons {
  margin-right: 10px;
  font-size: 12px;
}
</style>

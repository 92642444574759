<template>
  <DraftGuard :work-in-progress="isCreateModalShow">
    <ModalDialog
      v-model="isCreateModalShow"
      :header="textsUser.new"
      :confirmText="textsGlobal.save"
      :isLoading="isLoading"
      @confirm="createUser"
      @close="onClose"
      @cancel="onCancel"
      @open="onOpen"
      :persistent="true"
    >
      <template #activator="{ on, attrs }">
        <v-btn large icon text v-bind="attrs" v-on="on">
          <IconBox :tooltip="$t('user.new')" color="iconButton">
            stem-personPlus
          </IconBox>
        </v-btn>
      </template>

      <v-container
        slot="content"
        id="new-user-fields-wrapper"
        v-bind:class="isMobile && 'mobile'"
      >
        <v-layout>
          <v-flex xs5 py-1>
            <BaseCombobox
              :name="textsUser.login"
              :items="identityUsers.map((x) => x.userName)"
              required
              darken
              clearable
              v-model.trim="user.login"
              :func="searchIdentityUser"
              :error-messages="errors.login"
              @input="$v.user.login.$touch()"
              @blur="$v.user.login.$touch()"
            ></BaseCombobox>
          </v-flex>
          <v-flex xs7 py-1>
            <CategoryField
              class="pa-0"
              autocompleteField
              return-object
              filled
              :edit="true"
              :options="departments"
              :error-messages="errors.department"
              :label="textsCrmContact.department"
              v-model="user.department"
            ></CategoryField>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs5 py-1>
            <BaseInput
              :name="textsCrmContact.firstname"
              required
              darken
              v-model.trim="user.firstname"
              :error-messages="errors.firstname"
              @input="$v.user.firstname.$touch()"
              @blur="$v.user.firstname.$touch()"
            ></BaseInput>
          </v-flex>
          <v-flex xs7 py-1>
            <BaseInput
              :name="textsCrmContact.surname"
              required
              darken
              v-model.trim="user.surname"
              :error-messages="errors.surname"
              @input="$v.user.surname.$touch()"
              @blur="$v.user.surname.$touch()"
            ></BaseInput>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs5 py-1>
            <BaseInput
              :name="textsCrmContact.email"
              :required="user.isLocalUser"
              darken
              :value="user.email"
              :error-messages="additionalEmailErrorMessage || errors.email"
              @input="onEmailFieldChange"
              @blur="onEmailFieldBlur"
            ></BaseInput>
          </v-flex>
          <v-flex xs7 py-1>
            <BaseInput
              :name="textsCrmContact.phone"
              required
              darken
              mask="###-###-###"
              :value="user.phone"
              :error-messages="additionalPhoneErrorMessage || errors.phone"
              @input="onPhoneFieldChange"
              @blur="onPhoneFieldBlur"
            ></BaseInput>
          </v-flex>
        </v-layout>
        <v-layout v-for="(item, index) in user.rolesLCategories" :key="index">
          <v-flex xs5 py-1>
            <BaseSelect
              :name="textsUser.role"
              :items="roles"
              item-text="name"
              item-value="id"
              required
              darken
              :disabled="!!user.rolesLCategories[index + 1]"
              v-model="user.rolesLCategories[index].roleId"
              :error-messages="
                errors.rolesLCategories[index]
                  ? errors.rolesLCategories[index].roles
                  : []
              "
              @input="$v.user.rolesLCategories.$touch()"
              @blur="$v.user.rolesLCategories.$touch()"
            ></BaseSelect>
          </v-flex>
        </v-layout>
        <ModalWindow ref="confirmModal" />
      </v-container>
    </ModalDialog>
  </DraftGuard>
</template>

<script>
import { mapGetters } from "vuex";
import { email } from "vuelidate/lib/validators";

import { EMAIL, MOBILEPHONE } from "@/models/channelTypes.js";
import axios from "@/plugins/http";
import { EmitWarning, EmitSuccess, EmitError } from "@/eventBus.js";
import UserValidators from "@/mixins/validators/user-validators";

import CrmCustomersService from "@/services/CrmCustomersService";
import NewUserService from "@/services/NewUserService";

import ModalDialog from "../Shared/ModalDialog";
import DraftGuard from "../DraftGuard";
import CategoryField from "../Fields/CategoryField";
import ModalWindow from "@/components/Shared/ModalWindow";

export default {
  name: "NewUser",
  components: { DraftGuard, ModalDialog, CategoryField, ModalWindow },
  mixins: [UserValidators],
  mounted() {
    this.getRoles();
    this.getDepartments();
  },
  data() {
    return {
      isCreateModalShow: false,
      user: this.emptyUser(),
      userExistsInIdentity: false,
      textsUser: this.$t("user"),
      textsCrmContact: this.$t("newContact"),
      textsGlobal: this.$t("global"),
      roles: [],
      identityUsers: [],
      isLoading: false,
      departments: [],
      isEmailTaken: false,
      isPhoneTaken: false,
    };
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    additionalEmailErrorMessage() {
      return this.isEmailTaken ? this.$t("rules.isUniqueEmail") : null;
    },
    additionalPhoneErrorMessage() {
      return this.isPhoneTaken ? this.$t("rules.isUniquePhone") : null;
    },
  },
  watch: {
    "user.login"() {
      let selectedIdentityUser = this.identityUsers.find(
        (x) => x.userName === this.user.login
      );
      if (selectedIdentityUser) {
        this.user.id = selectedIdentityUser.id;
        this.user.firstname = selectedIdentityUser.firstName;
        this.user.surname = selectedIdentityUser.lastName;
        this.user.email = selectedIdentityUser.email;
        this.user.phone = selectedIdentityUser.phoneNumber;
      } else if (!this.user.login) {
        this.user.firstname = "";
        this.user.surname = "";
        this.user.email = "";
        this.user.phone = "";
      }
    },
  },
  methods: {
    onEmailFieldChange(newValue) {
      this.isEmailTaken = false;
      this.user.email = newValue ? newValue.trim() : null;
    },
    onPhoneFieldChange(newValue) {
      this.$v.user.phone.$touch();
      this.isPhoneTaken = false;
      this.user.phone = newValue ? newValue.trim() : null;
    },
    async onEmailFieldBlur() {
      if (this.user.email !== "" && email(this.user.email)) {
        this.isEmailTaken = await CrmCustomersService.CheckIsMediumTaken(
          this.user.email,
          EMAIL,
          this.user.customerId
        );
      }
    },
    async onPhoneFieldBlur() {
      if (this.user.phone >= 9) {
        this.isPhoneTaken = await CrmCustomersService.CheckIsMediumTaken(
          this.user.phone,
          MOBILEPHONE,
          this.user.customerId
        );
      }
    },
    onClose() {
      this.user = this.emptyUser();
      this.$v.$reset();
      this.isCreateModalShow = false;
    },
    onCancel() {
      this.$refs.confirmModal
        .open(
          this.$t("global.confirm").toUpperCase(),
          this.$t("common.closeConfirm")
        )
        .then((result) => {
          if (result) {
            this.onClose();
          }
        });
    },
    searchIdentityUser(val) {
      return NewUserService.GetIdentityUsers(val)
        .then((response) => {
          this.identityUsers = response.data || [];
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            // obsluga catcha - zeby nie sypało błędami w konsoli o cancelu
          }
        });
    },
    getRoles() {
      NewUserService.GetRoles().then((response) => {
        this.roles = response;
      });
    },
    getDepartments() {
      return CrmCustomersService.GetDepartments().then((response) => {
        this.departments = response;
      });
    },
    onOpen() {
      this.user.rolesLCategories[0].roles = this.roles;
    },
    selectDataFilter(items, selected) {
      return items.filter((item) => selected.indexOf(item.id) == -1);
    },
    available(isDelete) {
      let rolesLCategories = isDelete
        ? this.user.rolesLCategories.slice(0, -1)
        : this.user.rolesLCategories;
      let roles = this.selectDataFilter(
        this.roles,
        rolesLCategories.map((m) => m.roleId)
      );

      return { roles };
    },
    async createUser() {
      if (this.isEmailTaken || this.isPhoneTaken) {
        EmitError(this.$t("user.phoneAndEmailFieldMustBeUnique"));
        return;
      }

      if (!(await this.validateModelAsync())) return;

      this.isLoading = true;
      let userCheck = { ...this.user };
      userCheck.rolesLCategories = [];

      try {
        const loginExists = await NewUserService.isUserLoginExists(userCheck);
        if (loginExists) return EmitWarning(this.$t("user.userLoginExists"));

        await NewUserService.Create(this.user);
        this.$emit("user-created");
        EmitSuccess(
          this.$t("user.createSuccess", {
            name: this.user.firstname,
            surname: this.user.surname,
          })
        );
        this.onClose();
        // eslint-disable-next-line no-empty
      } catch {
      } finally {
        this.isLoading = false;
      }
    },
    emptyUser() {
      return {
        firstname: "",
        surname: "",
        login: "",
        email: "",
        phone: "",
        department: null,
        rolesLCategories: [{ roleId: null, roles: [] }],
        isLocalUser: true,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  color: $text-color;
  text-transform: uppercase;
}
::v-deep.v-input__slot:before {
  border-color: #fbfbfb !important;
}
#new-user-fields-wrapper.mobile > .layout {
  flex-direction: column;
}
#new-user-fields-wrapper.mobile > .layout > .flex {
  max-width: 100%;
}
</style>

<template>
  <DraftGuard :work-in-progress="isEditing">
    <div>
      <ModalDialog
        v-model="isModalShow"
        :header="userText.details"
        @confirm="onSave"
        @close="onClose"
        @open="onOpen"
        @cancel="onCancel"
        :persistent="isEditing"
        :editMode="isEditing"
        :isLoading="isLoading"
      >
        <template #activator="{}">
          <div v-on:click="isModalShow = true">
            <slot name="button"></slot>
          </div>
        </template>
        <div
          slot="actions"
          id="user-details-actions"
          v-bind:class="isMobile && 'mobile'"
        >
          <v-btn
            v-can:CFG_WRITE
            @click="isConfirmResetPasswordModalShown = true"
            large
            icon
            text
          >
            <IconBox :tooltip="userText.resetUserPassword" color="iconButton">
              stem-resetPassword
            </IconBox>
          </v-btn>
          <v-btn
            v-can:CFG_WRITE
            v-show="!isEditing"
            @click="isEditing = true"
            large
            icon
            text
          >
            <IconBox :tooltip="userText.editUser" color="iconButton">
              stem-edit
            </IconBox>
          </v-btn>

          <v-btn
            v-show="canBeDeleted"
            v-can:CFG_WRITE
            @click="isConfirmDeleteModalShown = true"
            large
            icon
            text
          >
            <IconBox :tooltip="userText.deleteUser" color="iconButton">
              stem-delete
            </IconBox>
          </v-btn>
        </div>
        <v-container slot="content">
          <v-progress-linear
            class="loader"
            color="loaderColor"
            v-show="isFetching"
            :indeterminate="true"
          ></v-progress-linear>

          <v-flex xs12>
            <v-layout
              ml-3
              justify-space-around
              wrap
              id="user-details-grid-container"
              v-bind:class="isMobile && 'mobile'"
            >
              <CategoryField
                :edit="false"
                textField
                :label="userText.login"
                v-model="user.login"
                :class="flexOptions"
                >{{ user.login }}</CategoryField
              >

              <CategoryField
                :class="flexOptions"
                :edit="isEditing"
                autocompleteField
                return-object
                :options="departments"
                :label="contactsText.department"
                v-model="user.department"
              ></CategoryField>

              <CategoryField
                :edit="isEditing"
                textField
                :label="userText.firstName"
                v-model.trim="user.firstname"
                :class="flexOptions"
                :error-messages="errors.firstname"
                @input="$v.user.firstname.$touch()"
                @blur="$v.user.firstname.$touch()"
                >{{ user.firstname }}</CategoryField
              >

              <CategoryField
                :edit="isEditing"
                textField
                :label="userText.surname"
                v-model.trim="user.surname"
                :class="flexOptions"
                :error-messages="errors.surname"
                @input="$v.user.surname.$touch()"
                @blur="$v.user.surname.$touch()"
                >{{ user.surname }}</CategoryField
              >

              <CategoryField
                :edit="isEditing"
                textField
                :label="userText.email"
                :value="user.email"
                :class="flexOptions"
                :error-messages="additionalEmailErrorMessage || errors.email"
                @input="onEmailFieldChange"
                @blur="onEmailFieldBlur"
                >{{ user.email }}</CategoryField
              >

              <CategoryField
                :edit="isEditing"
                textField
                :label="userText.phone"
                :value="user.phone"
                :class="flexOptions"
                mask="###-###-###"
                :error-messages="additionalPhoneErrorMessage || errors.phone"
                @input="onPhoneFieldChange"
                @blur="onPhoneFieldBlur"
                >{{ user.phone }}</CategoryField
              >

              <CategoryField
                :edit="false"
                textField
                v-model="user.insertDate"
                :class="flexOptionsSingleRowValue"
                :label="userText.insertDate"
                >{{ user.insertDate | moment("displayDate") }}</CategoryField
              >

              <v-flex xs12>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 my-3>
                <v-layout
                  justify-space-around
                  v-for="(item, index) in user.rolesLCategories"
                  :key="index"
                >
                  <CategoryField
                    :edit="isEditing && !isDefaultAdmin"
                    selectField
                    item-text="text"
                    item-value="id"
                    :options="
                      getRoleOptions(user.rolesLCategories[index].roleId)
                    "
                    :label="userText.role"
                    v-model="user.rolesLCategories[index].roleId"
                    :class="flexOptionsSingleRowValue"
                    :error-messages="
                      errors.rolesLCategories[index]
                        ? errors.rolesLCategories[index].roles
                        : []
                    "
                    @input="$v.user.rolesLCategories.$touch()"
                    @blur="$v.user.rolesLCategories.$touch()"
                  >
                    {{
                      roles.find(
                        (f) => f.id === user.rolesLCategories[index].roleId
                      )
                        ? roles.find(
                            (f) => f.id === user.rolesLCategories[index].roleId
                          ).name
                        : ""
                    }}
                  </CategoryField>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-container>
      </ModalDialog>
      <ConfirmModal
        v-model="isConfirmResetPasswordModalShown"
        @confirm="resetUserPassword()"
        :confirmText="userText.confirmResetPassword"
      ></ConfirmModal>
      <ConfirmModal
        v-model="isConfirmDeleteModalShown"
        @confirm="deactivateUser()"
        @cancel="isConfirmDeleteModalShown = false"
      ></ConfirmModal>
      <ModalWindow ref="confirmModal" />
    </div>
  </DraftGuard>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { email } from "vuelidate/lib/validators";

import { EMAIL, MOBILEPHONE } from "@/models/channelTypes.js";
import EventBus, { EmitSuccess, EmitError } from "@/eventBus.js";
import UserValidators from "@/mixins/validators/user-validators";

import NewUserService from "@/services/NewUserService";
import UsersService from "@/services/UsersService";
import CrmCustomersService from "@/services/CrmCustomersService";

import ModalDialog from "../Shared/ModalDialog";
import CategoryField from "../Fields/CategoryField";
import ConfirmModal from "@/components/Shared/ConfirmModal";
import DraftGuard from "../DraftGuard";
import ModalWindow from "@/components/Shared/ModalWindow";

export default {
  components: {
    DraftGuard,
    ModalDialog,
    CategoryField,
    ConfirmModal,
    ModalWindow,
  },
  mixins: [UserValidators],
  props: {
    userDetails: {
      required: true,
    },
  },
  data() {
    return {
      isConfirmDeleteModalShown: false,
      isConfirmResetPasswordModalShown: false,
      isModalShow: false,
      userText: this.$t("user"),
      contactsText: this.$t("contacts"),
      isEditing: false,
      user: this.userDetails,
      flexOptions: "my-2 xs5",
      flexOptionsSingleRowValue: "my-2 xs11",
      roles: [],
      isLoading: false,
      isFetching: false,
      departments: [],
      isEmailTaken: false,
      isPhoneTaken: false,
    };
  },
  methods: {
    ...mapActions("settings", ["fetchUserRestrictions"]),
    onClose() {
      this.isEditing = false;
      this.user = this.userDetails;
      this.$v.$reset();
    },
    onOpen() {
      this.isFetching = true;
      Promise.all([
        this.getUserRolesWithCategories(this.userDetails.id),
        this.getRoles(),
        this.getDepartments(),
      ])
        .then((res) => {
          this.roles = res[1];
          this.user = {
            ...this.userDetails,
            rolesLCategories: res[0],
          };
          this.departments = res[2];

          let available = this.available(true);
          this.lastUserPrivilege.roles = available.roles;
        })
        .finally(() => (this.isFetching = false));
    },
    onCancel() {
      this.$refs.confirmModal
        .open(
          this.$t("global.confirm").toUpperCase(),
          this.$t("common.closeConfirm")
        )
        .then((result) => {
          if (result) {
            this.onClose();
          }
        });
    },
    getRoles() {
      return NewUserService.GetRoles();
    },
    getDepartments() {
      return CrmCustomersService.GetDepartments(
        this.user.department ? this.user.department.id : null
      );
    },
    getUserRolesWithCategories(id) {
      return UsersService.GetUserRolesWithCategories(id);
    },
    selectDataFilter(items, selected) {
      return Array.isArray(items)
        ? items.filter((item) => selected.indexOf(item.id) == -1)
        : [];
    },
    available(isDelete) {
      let rolesLCategories = isDelete
        ? this.user.rolesLCategories.slice(0, -1)
        : this.user.rolesLCategories;
      let roles = this.selectDataFilter(
        this.roles,
        rolesLCategories.map((m) => m.roleId)
      );

      return { roles };
    },
    async onSave() {
      if (this.isEmailTaken || this.isPhoneTaken) {
        EmitError(this.$t("user.phoneAndEmailFieldMustBeUnique"));
        return;
      }

      if ((await this.validateModelAsync()) && this.isEditing) {
        this.editUserSave();
      }
    },
    editUserSave() {
      this.isLoading = true;
      UsersService.Edit(this.user)
        .then(() => {
          EventBus.$emit("eb-update-user-list");
          EmitSuccess(
            this.$t("user.editSuccess", {
              name: this.user.firstname,
              surname: this.user.surname,
            })
          );
          this.isModalShow = false;

          if (this.$store.getters["oidcStore/oidcUser"]) {
            this.fetchUserRestrictions({
              userId: this.$store.getters["oidcStore/oidcUser"].userId,
            });
          }
          this.onClose();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deactivateUser() {
      this.isConfirmDeleteModalShown = false;

      UsersService.Deactivate([this.user.id]).then(() => {
        EmitSuccess(this.$t("user.deleteUserSuccess"));
        EventBus.$emit("eb-update-user-list");
        this.isModalShow = false;
      });
    },
    resetUserPassword() {
      UsersService.ResetUserPassword(this.user.id).then(() => {
        EmitSuccess(this.$t("user.resetUserPasswordSuccess"));
        this.isModalShow = false;
      });
    },
    getRoleOptions(roleId) {
      return this.roles
        .filter(
          (r) =>
            this.avaiableRoles.some((a) => a.id === r.id) || r.id === roleId
        )
        .map((o) => ({ id: o.id, text: o.name }));
    },
    onEmailFieldChange(newValue) {
      this.isEmailTaken = false;
      this.user.email = newValue ? newValue.trim() : null;
    },
    async onEmailFieldBlur() {
      if (this.user.email !== "" && email(this.user.email)) {
        this.isEmailTaken = await CrmCustomersService.CheckIsMediumTaken(
          this.user.email,
          EMAIL,
          this.user.customerId
        );
      }
    },
    onPhoneFieldChange(newValue) {
      this.$v.user.phone.$touch();
      this.isPhoneTaken = false;
      this.user.phone = newValue ? newValue.trim() : null;
    },
    async onPhoneFieldBlur() {
      if (this.user.phone >= 9) {
        this.isPhoneTaken = await CrmCustomersService.CheckIsMediumTaken(
          this.user.phone,
          MOBILEPHONE,
          this.user.customerId
        );
      }
    },
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),
    ...mapGetters("settings", ["isMobile"]),
    addButtonEnabled() {
      let available = this.available(false);
      return available.roles[0] && this.lastUserPrivilege.roleId;
    },
    lastUserPrivilege() {
      return this.user.rolesLCategories[this.user.rolesLCategories.length - 1];
    },
    avaiableRoles() {
      return this.roles
        .filter((r) => !this.selectedRoleIds.includes(r.id))
        .map((o) => ({ id: o.id, text: o.name }));
    },
    selectedRoleIds() {
      return this.user.rolesLCategories.map((rlc) => rlc.roleId);
    },
    canBeDeleted() {
      return this.user.id !== this.oidcUser.userId && !this.isDefaultAdmin;
    },
    isDefaultAdmin() {
      return (
        this.user.id === "82c92375-7206-4181-96c8-feaf994f897e" &&
        this.user.login === "admin"
      );
    },
    additionalEmailErrorMessage() {
      return this.isEmailTaken ? this.$t("rules.isUniqueEmail") : null;
    },
    additionalPhoneErrorMessage() {
      return this.isPhoneTaken ? this.$t("rules.isUniquePhone") : null;
    },
  },
};
</script>
<style scoped lang="scss">
#user-details-grid-container.mobile {
  flex-direction: column;
  margin-left: 0 !important;
}
#user-details-grid-container.mobile .flex {
  max-width: 100%;
  word-wrap: break-word;
}
#user-details-grid-container.mobile .layout {
  flex-direction: column;
}
#user-details-actions.mobile {
  display: flex;
  justify-content: space-between;
}
</style>

<template>
  <div>
    <v-btn icon text large class="delete-icon" @click.stop="isModalShow = true">
      <IconBox color="iconButton" :tooltip="$t('user.restoreDeletedUser')">
        stem-deleteUndo
      </IconBox>
    </v-btn>

    <v-dialog v-model="isModalShow" width="700">
      <v-card class="content-card">
        <div class="content-header">
          <div class="header-title">
            {{ $t("user.restoreDeletedUser").toUpperCase() }}
          </div>
        </div>

        <div class="content-details">
          <SelectWithAutocomplete
            item-value="id"
            :value="selectedItemId"
            :items="items"
            :name="$t('common.autoCompleteAsyncHint')"
            :item-text="(item) => `${item.name}`"
            @input="onChange"
          />
        </div>

        <div class="content-footer">
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            @click="onCancel"
          >
            {{ $t("common.close") }}
          </v-btn>
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            :disabled="!selectedItemId"
            @click="onConfirm"
          >
            {{ $t("common.choose") }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UsersService from "@/services/UsersService";

import SelectWithAutocomplete from "@/components/Basic/SelectWithAutocomplete";

export default {
  name: "SearchDeletedUsers",
  components: { SelectWithAutocomplete },
  props: {},
  data() {
    return {
      isModalShow: false,
      selectedItemId: null,
      items: [],
    };
  },
  methods: {
    onCancel() {
      this.isModalShow = false;
    },
    onConfirm() {
      this.isModalShow = false;
      const selectedItem = this.items.find(
        (item) => item.id === this.selectedItemId
      );
      this.$emit("onSelect", selectedItem);
    },
    onChange(newValue) {
      this.selectedItemId = newValue;
    },
  },
  watch: {
    isModalShow(isShown) {
      if (isShown) {
        this.selectedItemId = null;

        UsersService.GetDeletedUsers("alek").then((response) => {
          this.items = response;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-icon {
  height: 28px;
  widows: 28px;
}
.content-card {
  height: 450px;
  padding: 40px;
  display: flex;
  flex-direction: column;

  .content-header {
    flex: 0 0 50px;

    .header-title {
      color: $text-title;
      padding-top: 20px;
    }
  }

  .content-details {
    flex: 1 0 auto;
    padding-top: 30px;
  }

  .content-footer {
    flex: 0 0 50px;
    margin-left: auto;

    :not(:first-child) {
      margin-left: 10px;
    }
  }
}
</style>
